// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["N_YzveVGX"];

const variantClassNames = {N_YzveVGX: "framer-v-yjfbaa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "N_YzveVGX", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-VLuXg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-yjfbaa", className)} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"N_YzveVGX"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><SVG className={"framer-1uht6uj"} layout={"position"} layoutDependency={layoutDependency} layoutId={"blecWI0pq-shape"} opacity={1} style={{backgroundColor: "rgb(255, 255, 255)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 64 62\"><path d=\"M 53.849 52.828 L 44.654 52.828 L 44.654 38.441 C 44.654 35.011 44.66 30.594 39.871 30.594 C 35.082 30.594 34.349 34.332 34.349 38.191 L 34.349 52.827 L 25.153 52.827 L 25.153 23.242 L 33.981 23.242 L 33.981 27.285 L 34.105 27.285 C 35.905 24.21 39.252 22.374 42.815 22.506 C 52.135 22.506 53.853 28.631 53.853 36.598 Z M 14.778 19.198 C 11.83 19.199 9.441 16.812 9.44 13.868 C 9.44 10.924 11.828 8.536 14.776 8.536 C 17.723 8.535 20.112 10.922 20.113 13.866 C 20.113 15.28 19.551 16.636 18.551 17.636 C 17.55 18.636 16.193 19.198 14.778 19.198 M 19.375 52.828 L 10.17 52.828 L 10.17 23.242 L 19.375 23.242 Z M 58.434 0.005 L 5.549 0.005 C 3.05 -0.024 1 1.977 0.97 4.474 L 0.97 57.526 C 0.999 60.024 3.049 62.026 5.549 61.999 L 58.434 61.999 C 60.939 62.031 62.997 60.029 63.032 57.526 L 63.032 4.47 C 62.996 1.968 60.938 -0.032 58.434 0.001\" fill=\"rgba(0,0,0,1)\"></path></svg>"} svgContentId={3561443715} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-VLuXg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VLuXg .framer-lt8qcx { display: block; }", ".framer-VLuXg .framer-yjfbaa { height: 64px; overflow: hidden; position: relative; width: 66px; }", ".framer-VLuXg .framer-1uht6uj { flex: none; height: 62px; left: calc(50.00000000000002% - 64px / 2); position: absolute; top: calc(50.00000000000002% - 62px / 2); width: 64px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 66
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const Framerd_RhR7bMu: React.ComponentType<Props> = withCSS(Component, css, "framer-VLuXg") as typeof Component;
export default Framerd_RhR7bMu;

Framerd_RhR7bMu.displayName = "Social Icons / LinkedIn";

Framerd_RhR7bMu.defaultProps = {height: 64, width: 66};

addFonts(Framerd_RhR7bMu, [])